<template>
  <div>
    <div class="condition-filtrate-box">
      <div class="left-item">
        <el-input
          class="select-width id-code-width"
          size="medium"
          v-model="queryForm.searchValue"
          placeholder="社区名称" />
      </div>

      <div class="right-item">
        <el-button type="primary" size="small" @click="queryUserInfo">查询</el-button>
        <el-button type="primary" size="small" @click="addUserInfo">新增</el-button>
      </div>
    </div>

    <TableModel
      :is-show-select="true"
      :is-show-tabs="false"
      :table-data="tableData"
      table-size="medium">
      <el-table-column label="操作" width="180" align="center">
        <template slot-scope="{ row }">
          <el-link type="primary" @click="editTableFun(row)">编辑</el-link>
          <el-link v-if="true" type="danger" @click="deleteTableFun(row)">停用</el-link>
          <el-link v-else type="primary" @click="deleteTableFun(row)">启用</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="社区名称"></el-table-column>
      <el-table-column prop="code" label="地址" />
      <el-table-column prop="code" label="负责人姓名" />
      <el-table-column prop="code" label="负责人电话" />
      <el-table-column prop="id" label="状态">
        <template slot-scope="{ row }">
          <el-tag size="small" v-if="row.status == 1">启用</el-tag>
          <el-tag size="small" type="danger" v-else-if="row.status == 2">停用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="cardNo" label="最后更新时间" width="200" />
    </TableModel>

    <div class="content-page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageObj.pageNumber"
        :page-size="pageObj.pageSize"
        :total="pageObj.total"
        :page-sizes="[10, 20, 30, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>

    <el-dialog title="编辑社区信息" :visible.sync="dialogFormVisible" :close-on-click-modal="false" >
      <el-form :model="form" ref="addForm">
        <el-form-item label="社区名称:" label-width="120px" prop="name" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-input v-model="form.name" ></el-input>
        </el-form-item>
        <el-form-item label="简称:" label-width="120px" prop="name" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-input v-model="form.name" ></el-input>
        </el-form-item>
        <el-form-item label="社区地址:" label-width="120px" prop="name" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-input v-model="form.name" ></el-input>
        </el-form-item>
        <el-form-item label="负责人姓名:" label-width="120px" prop="name" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-input v-model="form.name" ></el-input>
        </el-form-item>
        <el-form-item label="角色名称:" label-width="120px" prop="name" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-input v-model="form.name" ></el-input>
        </el-form-item>
        <el-form-item label="负责人电话:" label-width="120px" prop="name" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-input v-model="form.name" ></el-input>
        </el-form-item>
        <el-form-item label="状态:" label-width="120px" prop="resource" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-radio-group v-model="form.resource">
            <el-radio label="启用"></el-radio>
            <el-radio label="停用"></el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogSubmit('addForm')">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="操作确认" :visible.sync="dialogFormVisible2" :close-on-click-modal="false" >
      <div><i style="color:#FF9900;font-size:22px;" class="el-icon-question"></i>{{dialogFormVisible2Text}}</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="dialogSubmit2()">确 定</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import { exteriorUrl } from '@/api';
import TableModel from '../../components/TableModel';
import { roleList,roleSave,roleUpdate  } from '../../api/SystemManagement/index.js';
import { mapState } from 'vuex';

export default {
  name: "CommunityManage",
  components: { TableModel },
  data() {
    return {
      queryForm: {
        searchValue: "",
        status: "",
        isolationPointId: ""
      },
      tableData: [{test:1}], //表单数据
      pageObj:{
        pageNumber: 1,
        pageSize: 20,
        total: 0,
      },
      dialogFormVisible:false,
      form:{//表单

      },
      dialogFormVisible2:false,

      dialogFormVisible2Text:'确定要停用社区【社区名称】吗？'

    }
  },

  computed: {
    ...mapState({
      isolationList: state => state.user.isolationList,
    }),
  },

  created() {

  },

  methods: {
    getUserInfo(){
      let params = Object.assign({},this.queryForm,this.pageObj);
      roleList({params}).then((res)=>{
        let { code, data, msg } = res.data;
        if(code == 200){
          this.tableData = data.records;
          this.pageObj.total = data.total;
        }
      })
    },
    //查询
    queryUserInfo() {
      this.getUserInfo();
    },
    //新增
    addUserInfo() {
      this.dialogFormVisible = true;
    },
    //表单新增
    dialogSubmit(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormFun();
          this.dialogFormVisible = false
        } else {
          return false;
        }
      });
    },
    submitFormFun(){
      console.log('表单提交');
    },

    //table操作
    editTableFun(){
      this.dialogFormVisible = true;
    },

    //起停用
    deleteTableFun(){
      this.dialogFormVisible2 = true;
    },
    dialogSubmit2(){
      console.log('删除');
    },


    //分页变化
    handleSizeChange(val) {
      this.pageObj.pageSize = val;
      this.getUserInfo();
    },
    handleCurrentChange(val) {
      this.pageObj.pageNumber = val;
      this.getUserInfo();
    },


  }
}
</script>

<style scoped lang="scss">
@import "../../static/css/condition-filtrate.scss";

.content-page {
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
}

.el-link {
  margin-right: 10px;
}
</style>
